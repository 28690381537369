
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { PolicyMenu } from '@/core/config/MainMenuConfig';
import Panel from "@/components/base/panel/Panel.vue";

import DocService from "@/core/services/DocService";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import MenuItem from "@/layout/header/menu/MenuItem.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import ShortPageForm from "@/views/page/ShortPageForm.vue";


export default defineComponent({
  name: "kt-menu",
  components: {ShortPageForm, QuickAction, MenuItem, Panel},
  setup() {
    const store = useStore();
    const state = reactive({
      showPanel: false,
      title: '',
      model: {name: '', type: '', body: '', parentId: '', description: ''}
    });
    const submitting = ref(false);
    const {t, te} = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    const menuData = computed(() => store.state.DocModule.menuData)
    store.dispatch(Actions.LOAD_MENU);
    return {
      hasActiveChildren,
      PolicyMenu,
      asideMenuIcons,
      version,
      translate,
      state,
      store,
      submitting,
      menuData,
    };
  },
  methods: {
    onClickItem(item) {
      if (item.type === 'COLLECTION' || item.type === 'FOLDER') {
        if (item.active == false) {
          DocService.findAll<any>({key: {parentId: item.id, s: 'sort:order:asc'}}).then(res => {
            this.store.commit(Mutations.SET_PAGE_ITEMS, {data: res.data, item: item})
          })
        }
        if (item.type === 'COLLECTION') {
          this.$router.push("/doc/collection/" + item.id);
        } else {
          this.$router.push("/doc/folder/" + item.id);
        }
      } else {
        this.$router.push("/doc/" + item.id);
      }
      this.store.dispatch(Actions.LOAD_SUB_MENU, item);
    },


    onMove(item, docId) {
      DocService.updateOrder(item.id, docId).then(updated => {
        if (item.type !== 'COLLECTION') {
          DocService.findAll<any>({key: {parentId: item.parentId, s: 'sort:order:asc'}}).then(res => {
            this.store.commit(Mutations.SET_PAGE_ITEMS, {data: res.data, item: updated.parent})
          })
        } else {
          DocService.findAll<any>({key: {type: 'COLLECTION', s: 'sort:order:asc'}}).then(res => {
            this.store.commit(Mutations.SET_COLLECTIONS, res.data)
          })
        }
      })
    },
    onResetModel(type, parentId) {
      this.state.model.name = ''
      this.state.model.type = type
      this.state.model.parentId = parentId
      this.state.model.body = ''
      this.state.model.description = ''
    },
    onClosePanel() {
      this.state.showPanel = false
    },

    addCollection() {
      this.onResetModel('COLLECTION', '')
      this.state.title = 'New Collection'
      this.state.showPanel = true
    },
    addFolder(parentId) {
      this.onResetModel('FOLDER', parentId)
      this.state.title = 'New Folder'
      this.state.showPanel = true;
    },
    addPage(parentId) {
      this.onResetModel('PAGE', parentId)
      this.state.title = 'New Doc'
      this.state.showPanel = true
    },
    onSavePage(data) {
      this.store.dispatch(Actions.ADD_PAGE, data)
      this.onClosePanel();
      this.store.commit(Mutations.SET_PAGE_TO_LIST, data);
    }
  }
});
