
import { computed, defineComponent } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import store from "@/store";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  setup() {
    const user = computed(()=>store.getters.currentUser)
    return{
        user,
    }
  }
});
