export const PolicyMenu = [
  {
    heading: "User Management",
    pages: [
      {
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        heading: "User",
        route: "/users"
      },
      {
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        heading: "Company",
        route: "/companies"
      },
      {
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        heading: "Product",
        route: "/products"
      },
      {
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        heading: "Template",
        route: "/templates"
      },
      {
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        heading: "Login Audits",
        route: "/login-audits"
      },
    ],
  },
]
